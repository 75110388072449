<template>
  <section class="tables">
    <Loader :loading="showLoader" />
    <div class="page-header">
      <h3 class="page-title">Card Methods</h3>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-12 col-md-3 col-lg-3">
                <select
                  class="custom-select form-control"
                  @change="sortByType"
                  v-model="type"
                >
                  <option value="" selected>Select Status</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
            </div>
          </div>
          <div class="table-responsive pb-2">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>Method</th>
                  <th>Enabled</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="card in cards" :key="card.id">
                  <td>
                    {{ card.name }}
                  </td>

                  <td>
                    <toggle-button
                      @change="onToggleChange(card.id, $event)"
                      v-model="card.active"
                      :labels="{ checked: 'true', unchecked: 'false' }"
                    />
                  </td>
                  <td>{{ card.description }}</td>
                </tr>
              </tbody>
            </table>
            <div class="col-12 pt-3 text-center" v-if="cards == ''">
              No data found!
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { setingsApi } from "../../../api";
import Loader from "../../../components/Loader";
export default {
  name: "card-methods",
  edit: {
    active: Boolean,
  },
  components: {
    Loader,
  },
  data() {
    return {
      cards: this.edit,
      showLoader: false,
      type: "",
      body: "",
    };
  },
  mounted() {
    this.cardMethods();
  },
  methods: {
    async cardMethods() {
      this.showLoader = true;
      const { data } = await setingsApi.cardMethods();
      this.cards = data.data.data;
      if (this.cards.active == 1) {
        this.cards.active = true;
      } else {
        this.cards.active = false;
      }
      this.showLoader = false;
    },

    async sortByType() {
      if (this.type === "") {
        this.cardMethods();
      } else {
        this.showLoader = true;
        const { data } = await setingsApi.sortByCardStatus(this.type);
        this.cards = data.data.data;
        this.showLoader = false;
      }
    },
    async onToggleChange(id, event) {

      this.body = { active: event.value };
      this.showLoader = true;
      const data = await setingsApi.activateCardMethods(id, {
        ...this.body,
      });
      this.showLoader = false;
      this.cardMethods();
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>

<style scoped></style>
